.create-company-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}

.grid-action-row {
  margin: auto;
  display: block;
}

.grid-action-icon {
  cursor: pointer;
  transition: color 3s;
  font-size: 15px;
  margin-left: 5px;
  border-radius: 5px;
  padding: 5px;
}
.grid-action-icon:hover {
  color: rgb(34, 98, 236);
}

.ant-alert {
  width: fit-content;
  margin: auto;
}
